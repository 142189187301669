import * as React from "react"
import Seo from "./seo"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "./layout"

const MdxLayout = ({ children, location }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  )
  const siteTile = site.siteMetadata?.title || `Title`

  return (
    <Layout location={location} title={siteTile}>
      <Seo title="关于我" />
      {children}
    </Layout>
  )
}

export default MdxLayout
