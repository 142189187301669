import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/components/mdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`关于`}</h1>
    <blockquote>
      <p parentName="blockquote">{`千载太虚无非梦，一段衷情不肯休。`}<br />{`
梦醒人间看微雨，江山还似旧温柔。`}<br />{`
--《逍遥游》`}</p>
    </blockquote>
    <p>{`久未仰望星空。`}</p>
    <p>{`蝉鸣、蛙叫、夏日凉风、深邃夜空漫天星子依稀梦里。`}</p>
    <p>{`折腰久，五斗米，碗中食，苟延一世。`}</p>
    <p>{`何甘，何苦。`}</p>
    <p>{`乘梦来，梦无言。`}</p>
    <p>{`便亲手在此写下，我所有的梦...`}</p>
    <h3>{`hasayake 的含义`}</h3>
    <p><strong parentName="p">{`"hasayake"`}</strong>{` 源自于极喜爱的周传雄的一首作品 --`}<strong parentName="p">{`《哈萨雅琪》`}</strong>{`，其实它亦是由日语 `}<strong parentName="p">{`"朝焼け"`}</strong>{` 音译中而来，意为 `}<strong parentName="p">{`"日出"`}</strong>{`。`}</p>
    <p>{`哈哈，写到这里。突然发现歌名与 `}<strong parentName="p">{`《黄昏》`}</strong>{` 是呼应的。`}</p>
    <p>{`分外喜悦...`}</p>
    <h3>{`做个介绍`}</h3>
    <p>{`以 `}<strong parentName="p">{`"hasayake"`}</strong>{` 行走于 `}<strong parentName="p">{`Internet`}</strong>{`。 😄`}</p>
    <p>{`江西人，江右民系。生于九七，夏至将至。`}</p>
    <p>{`从事 `}<strong parentName="p">{`前端开发工程师 (Front-end development engineer)`}</strong>{` 的工作。`}</p>
    <p>{`偶尔低头编码，多数经历担任部门前端 Leader，下辖四五人至十余人。`}</p>
    <p>{`厚颜自许，有点审美，懂点设计，服务端有所涉猎。`}</p>
    <p>{`编码洁癖，软件洁癖...`}</p>
    <h3>{`音乐、电影及书籍`}</h3>
    <p>{`关于音乐，自小受到父母的影响，会听一些旧时代的歌曲。`}</p>
    <p>{`《口是心非》、《你的样子》、《灰色轨迹》、《黄昏》是我常听的。`}</p>
    <p>{`记忆中印象最深刻的电影，只有《倩女幽魂》与《泰坦尼克号》了。`}</p>
    <p>{`幼时父亲送给我一本《苦儿流浪记》，由此开始阅读一些世界经典名著。`}</p>
    <p>{`高中生涯看了三百余本小说，以武侠题材居多。`}</p>
    <p>{`音乐、电影及书籍，它们或多或少的影响了我对这个世界的思辨审美。`}</p>
    <h3>{`在这里做什么`}</h3>
    <p>{`为自己留下一席之地，曾经存在于某个时空。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`"不至于，十年后甚至更远，回头望，空空如也"。`}</em></strong></p>
    <h3>{`找到我`}</h3>
    <ul>
      <li parentName="ul">{`网易云音乐: `}<a parentName="li" {...{
          "href": "https://music.163.com/#/user/home?id=5840872"
        }}>{`万叶千声`}</a></li>
      <li parentName="ul">{`Email: `}<a parentName="li" {...{
          "href": "mailto:hasayake97@gmail.com"
        }}>{`hasayake97@gmail.com`}</a></li>
    </ul>
    <h3>{`博客历史`}</h3>
    <h5>{`使用过的域名`}</h5>
    <ul>
      <li parentName="ul"><del parentName="li"><a parentName="del" {...{
            "href": "http://www.skywheel.xyz"
          }}>{`www.skywheel.xyz`}</a></del></li>
      <li parentName="ul"><del parentName="li"><a parentName="del" {...{
            "href": "http://www.seewhite.site"
          }}>{`www.seewhite.site`}</a></del></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://www.ihasayake.cc"
        }}>{`www.ihasayake.cc`}</a></li>
    </ul>
    <p><strong parentName="p"><em parentName="strong">{`"每个域名都曾有一些难以忘怀的人事，时至今日，见自己，见众生"。`}</em></strong></p>
    <h5>{`曾使用过如下方式构建`}</h5>
    <p><a parentName="p" {...{
        "href": "https://pages.github.com/"
      }}>{`Github Page`}</a>{` -> `}<a parentName="p" {...{
        "href": "https://hexo.io/zh-cn/"
      }}>{`Hexo`}</a>{` ->
`}<a parentName="p" {...{
        "href": "https://gohugo.io/"
      }}>{`Hugo`}</a>{` -> `}<a parentName="p" {...{
        "href": "https://www.gatsbyjs.com/"
      }}>{`Gatsby`}</a>{`。`}</p>
    <p><strong parentName="p"><em parentName="strong">{`"斗转星移，人面桃花。变与不变，皆是常态"。`}</em></strong></p>
    <h3>{`致谢`}</h3>
    <ul>
      <li parentName="ul">{`评论系统: `}<a parentName="li" {...{
          "href": "https://waline.js.org/"
        }}>{`Waline`}</a></li>
      <li parentName="ul">{`音乐外链: `}<a parentName="li" {...{
          "href": "https://music.163.com/"
        }}>{`网易云音乐`}</a></li>
      <li parentName="ul">{`开发搭建: `}<a parentName="li" {...{
          "href": "https://www.gatsbyjs.com/"
        }}>{`Gatsby`}</a></li>
      <li parentName="ul">{`自动化构建部署: `}<a parentName="li" {...{
          "href": "https://vercel.com/"
        }}>{`vercel`}</a></li>
      <li parentName="ul">{`自由分享可商用的照片: `}<a parentName="li" {...{
          "href": "https://unsplash.com/"
        }}>{`unsplash`}</a></li>
    </ul>
    <p>{`在此对以上说明的公司及个人表达诚挚的感谢及敬意。`}</p>
    <p>{`感谢自由分享。`}</p>
    <p>{`感谢开源...😄`}</p>
    <hr></hr>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      